import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';
import LazyLoad from 'react-lazyload';
import { nanoid } from 'nanoid';
import DefaultLayout from '../layouts/Default';
import CopyBlock from '../components/CopyBlock';
import ChevronRight from '../icons/chevron-right.svg';
import Twitter from '../icons/Twitter.svg';
import SEO from '../components/SEO';
import NewsCard from '../components/NewsCard';

const ProfilePage = ({ data }) => {
	const {
		personName,
		personImage,
		personBioNode,
		companyPositionFullTitle,
		personEmail,
		personTwitterHandle,
		personTwitter,
		company,
		associatedCountries,
		schema,
		seo,
	} = data.datoCmsOurPeoplePage;

	const { nodes: relatedNewsArticles } = data.allMultisiteInTheNews;
	const { nodes: relatedPressReleases } = data.allMultisitePressReleases;
	const allRelatedArticles = [...relatedNewsArticles, ...relatedPressReleases];

	const location = useLocation();
	const title = seo?.title ? seo.title : '';
	const description = seo?.description ? seo.description : '';
	const parsedCountries = JSON.parse(associatedCountries);

	return (
		<>
			<SEO path={location.pathname} schema={schema} title={title} description={description} />
			<section className="bg-no-repeat bg-cover bg-secondary-100 bg-none">
				<div className="flex flex-col md:flex-row !pb-0 max-w-5xl xxl:max-w-6xl m-auto px-4">
					<div className="w-full pt-12 md:w-1/2">
						<p className="mb-2 text-sm text-black ">
							<a
								href="/"
								className="pr-2 text-sm underline hover:no-underline text-secondary"
							>
								Home
							</a>
							&#62;
							<a
								href="/our-people"
								className="px-2 text-sm underline hover:no-underline"
							>
								Our People
							</a>
							&#62;<span className="pl-2 text-sm">{personName}</span>
						</p>
						<div className="py-6">
							<h1 className="mb-2 text-4xl  md:text-5xl !font-normal">
								{personName}
							</h1>
							<p className="text-lg text-secondary-300 md:text-xl">
								{companyPositionFullTitle}, {company},{' '}
								{parsedCountries.length > 3 ? 'Global' : parsedCountries.join(', ')}
							</p>
						</div>
						<a
							className="inline-block px-4 py-2 my-2 text-sm font-bold duration-300 bg-white border-2 rounded-full text-brand-200 border-brand-200 hover:scale-105 hover:cursor-pointer hover:bg-brand-200 focus:bg-brand-200 hover:text-white focus:text-white"
							href={`mailto:${personEmail}?subject=Request comment from ${personName}`}
						>
							<span className="flex items-center gap-x-2">
								Request Contact
								<ChevronRight />
							</span>
						</a>
						{personTwitter && (
							<div className="flex my-1 gap-x-2">
								<Twitter className="text-brand-200" />
								<a
									className="font-bold text-brand-200"
									target="_blank"
									href={personTwitter}
									rel="noreferrer"
								>
									{personTwitterHandle}
								</a>
							</div>
						)}
					</div>
					<div className="flex justify-center w-full pt-1 md:w-1/2 md:mt-0">
						<LazyLoad>
							<GatsbyImage image={personImage.gatsbyImageData} />
						</LazyLoad>
					</div>
				</div>
			</section>
			<section>
				<div className="max-w-5xl px-4 py-16 m-auto lg:pb-32 xxl:max-w-6xl">
					<CopyBlock>{personBioNode.childMdx.body}</CopyBlock>
				</div>
			</section>
			{allRelatedArticles.length > 0 && (
				<section>
					<div className="max-w-5xl px-4 py-16 m-auto lg:pb-32 xxl:max-w-6xl">
						<h3 className="mb-5 text-2xl font-normal text-center">
							{personName} In the News
						</h3>
						<div className="block grid-cols-3 gap-3 lg:grid">
							{allRelatedArticles
								.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate))
								.map((newsItem) => (
									<div className="mb-4 last:mb-0" key={nanoid()}>
										<NewsCard key={nanoid()} newsItem={newsItem} />
									</div>
								))}
						</div>
					</div>
				</section>
			)}
		</>
	);
};

export const query = graphql`
	query profilePages($id: String, $personName: String) {
		datoCmsOurPeoplePage(slug: { ne: null }, id: { eq: $id }) {
			associatedCountries
			slug
			personName
			personEmail
			personTwitterHandle
			personTwitter
			company
			personImage {
				gatsbyImageData(layout: CONSTRAINED, width: 450, imgixParams: { auto: "compress" })
			}
			personBio
			personBioNode {
				childMdx {
					body
				}
			}
			companyPositionFullTitle
			schema
			seo {
				title
				description
				image {
					gatsbyImageData(
						layout: CONSTRAINED
						width: 450
						imgixParams: { auto: "compress" }
					)
				}
			}
		}
		allMultisiteInTheNews(filter: { personFeatured: { eq: $personName } }) {
			nodes {
				title
				isArticleInternal
				externalArticleLink
				releaseDate
				id
				slug
				personFeatured
				newsType
			}
		}
		allMultisitePressReleases(filter: { personFeatured: { eq: $personName } }) {
			nodes {
				title
				isArticleInternal
				externalArticleLink
				releaseDate
				id
				slug
				personFeatured
				newsType
			}
		}
	}
`;

const WrappedProfilePage = (props) => (
	<DefaultLayout fullWidth>
		<ProfilePage {...props} />
	</DefaultLayout>
);

export default WrappedProfilePage;
